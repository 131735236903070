import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Backup from '../../entities/admin/backup'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface BackupState extends ListState<Backup>{
    editModel:Backup;
    
}
class BackupModule extends ListModule<BackupState,any,Backup>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Backup>(),
        loading:false,
        editModel: new Backup(),
        activeList: new Array<Backup>(),
        path: 'backups'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async resetDB(context: ActionContext<BackupState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/reset-db', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const backupModule=new BackupModule();
export default backupModule;