import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Warehouse from '../../entities/stocks/warehouse'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface WarehouseState extends ListState<Warehouse>{
    editModel: Warehouse;
}
class WarehouseModule extends ListModule<WarehouseState, any, Warehouse>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10, 
        list: new Array<Warehouse>(),
        loading:false,
        editModel:new Warehouse(),
        activeList: new Array<Warehouse>(),
        path: 'warehouses'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastCode(context: ActionContext<WarehouseState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/warehouses/last-code').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.reference = result;

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: WarehouseState, model: Warehouse) {
            state.editModel = Object.assign({});
            state.editModel.reference = '';
            state.editModel.branch_id = Util.abp.session.branchId;
            state.editModel.employee_id = Util.abp.session.userId;
            state.editModel.management_type_id = Util.abp.setting.get('WAREHOUSE_MANAGE_TYPE');            
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
        },
    });
}
const warehouseModule=new WarehouseModule();
export default warehouseModule;