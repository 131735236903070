import Entity from '../entity'

export default class CashierHistory extends Entity<number>{
    cashier_id: number;
    user_id: number;
    cash_initial: number;
    bank_initial: number;
    card_initial: number;
    card_move: number;
    bank_move: number;
    cash_in: number;
    cash_out: number;
    balance: number;
    balance_real: number;
    diference: number;
    active: boolean;
    nro_trans_in: number;
    nro_trans_out: number;
    date_process: string;
    date_open: string;
    date_close: string;
    
    currencies: Array<any>;
}


