import Entity from '../entity'

export default class Tax extends Entity<number>{
    rate: number;
    code: string;
    symbol: string;
    name: string;
    active: boolean;
    behavior_id: number;
    country_id: number;
}


