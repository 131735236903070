import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Branch from '../../entities/admin/branch'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Address from '@/store/entities/customers/address';

interface BranchState extends ListState<Branch>{
    editModel: Branch;
    activeList: Array<Branch>;
    
}
class BranchModule extends ListModule<BranchState, any, Branch>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Branch>(),
        loading:false,
        editModel: new Branch(),
        activeList: new Array<Branch>(),
        path: 'branches'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<BranchState, any>, payload: any) {
            context.state.loading = true;
            if (payload.data.address != null && payload.data.address.ubigeo != null) {
                payload.data.address.department_id = payload.data.address.ubigeo.department;
                payload.data.address.province_id = payload.data.address.ubigeo.province;
                payload.data.address.district_id = payload.data.address.ubigeo.district;
            }

            let response = await Ajax.post('/api/branches', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
             
            if (response.data.result && context.state.activeList != undefined)
                context.state.activeList.push(response.data.result);

            context.state.loading = false;
            return response.data.result;
        },
        async update(context: ActionContext<BranchState, any>, payload: any) {
            context.state.loading = true;
            if (payload.data.address != null && payload.data.address.ubigeo != null) {
                payload.data.address.department_id = payload.data.address.ubigeo.department;
                payload.data.address.province_id = payload.data.address.ubigeo.province;
                payload.data.address.district_id = payload.data.address.ubigeo.district;
            }

            await Ajax.put('/api/branches/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async getAllActive(context: ActionContext<BranchState, any>, payload: any) {
            if (context.state.activeList != undefined && context.state.activeList.length > 0) return true;
            let params = (payload.data == undefined ? {} : payload.data);
            params.perPage = -1;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/branches', { params: params }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            if (context.state.activeList != undefined)
                context.state.activeList = reponse.data.result;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: BranchState, model: Branch) {
            state.editModel = Object.assign({});
            state.editModel.address = new Address();
            state.editModel.address.country_id = Util.abp.setting.get('DEFAULT_COUNTRY');
            state.editModel.company_id = 1;            
        },
        edit(state: BranchState, model: Branch) {
            state.editModel = model;
            if (state.editModel.address == null) {
                state.editModel.address = new Address();
                state.editModel.address.country_id = Util.abp.setting.get('DEFAULT_COUNTRY');
            }
        },
    });
}
const branchModule = new BranchModule();
export default branchModule;