import Entity from '../entity'

export default class BankAccount extends Entity<number>{
    name: string;
    bank_id: number;
    currency_id: number;
    supplier_id: number;
    account_number: string;
    cci: string;
    amount: number;
    active: boolean;
}

