import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Page from '../../entities/cms/page'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface PageState extends ListState<Page>{
    editModel: Page;
}
class PageModule extends ListModule<PageState, any, Page>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Page>(),
        loading:false,
        editModel:new Page(),
        activeList: new Array<Page>(),
        path: 'pages'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: PageState, model: Page) {
            state.editModel = model;
        },
        new(state: PageState, model: Page) {
            state.editModel = Object.assign({});
        },
    });
}
const pageModule=new PageModule();
export default pageModule;