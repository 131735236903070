import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import CashierHistory from '../../entities/cashiers/cashierhistory'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'
import Cashier from '@/store/entities/cashiers/cashier';
import PageResult from '@/store/entities/page-result';
import axios from 'axios'

interface CashierHistoryState extends ListState<CashierHistory>{
    editModel: CashierHistory;
    reportList: Array<any>;
    details: Array<any>;
    cashier: number;
}
class CashierHistoryModule extends ListModule<CashierHistoryState,any,CashierHistory>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<CashierHistory>(),
        loading:false,
        editModel: new CashierHistory(),
        activeList: new Array<CashierHistory>(),
        reportList: new Array<any>(),
        details: new Array<any>(),
        cashier: null,
        path: 'cashiers'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<CashierHistoryState, any>, payload: any) {
            if (payload.data.cashier == undefined || payload.data.cashier == null) payload.data.cashier = context.state.cashier;

            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '-history', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<CashierHistory>;
            context.state.totalCount = page.total;
            context.state.list = page.data;
            context.state.list.forEach(item => {
                if (item.diference < 0)
                    item['color'] = '#F44336';
                else if (item.diference > 0)
                    item['color'] = '#4CAF50';
            });
        },
        async openCashier(context: ActionContext<CashierHistoryState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path + '/open', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async closeCashier(context: ActionContext<CashierHistoryState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path + '/close', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.editModel = Object.assign({});
            context.state.loading = false;
            return response.data.result;
        },
        async getDetails(context: ActionContext<CashierHistoryState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/cashier-details', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.details = response.data.result;
        },
        async print(context: ActionContext<CashierHistoryState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/cachierhistory-receipt', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            let localUrl = Util.abp.setting.get('LOCAL_PRINTER_URL');
            let mode = Util.abp.setting.get('REMOTE_PRINTER_MODE');
            let method = Util.abp.setting.get('REMOTE_PRINTER_METHOD');
            let useRemote = Util.abp.setting.get('USE_REMOTE_PRINTER');

            if (mode == 'LOCAL' && useRemote == 1) {
                if (response.data.result.localUrl != undefined) localUrl = response.data.result.localUrl;
                if (method == 'POST') {
                    await axios.post(localUrl, response.data.result);
                } else {
                    let href: any = localUrl + "?endpoint=" + (url + 'api/payment-receipt') + "&token=" + window.abp.auth.getToken() + "&id=" + payload.id;
                    const link = document.createElement('a');
                    link.href = href;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    return '';
                    //await axios.get(localUrl, { params: response.data.result });
                }
                return '';
            } else
                return response.data.result;
        },
        async reportCashierHistory(context: ActionContext<CashierHistoryState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/cashier-history', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: CashierHistoryState, currencies: Array<any>) {
            var today = new Date();
            state.editModel = Object.assign({});
            state.editModel.date_process = Util.abp.clock.today();
            state.editModel.currencies = [];
            if (currencies != undefined) {
                currencies.forEach(item => {
                    let curr = Object.assign({});
                    curr.cash_initial = 0;
                    curr.bank_initial = 0;
                    curr.card_initial = 0;
                    curr.currency_id = item.id;
                    curr.currency = item;
                    state.editModel.currencies.push(curr);
                });
            }
        },
        load(state: CashierHistoryState, cashier: Cashier) {
            state.cashier = cashier.id;
            if (cashier.history != null) {
                state.editModel = cashier.history;
            } else {
                state.editModel.cashier_id = cashier.id;
                state.editModel.cash_initial = 0;
                state.editModel.bank_initial = 0;
                state.editModel.card_initial = 0;
                state.editModel.card_move = 0;
                state.editModel.bank_move = 0;
                state.editModel.cash_in = 0;
                state.editModel.cash_out = 0;
                state.editModel.balance = 0;
                state.editModel.balance_real = 0;
                state.editModel.active = false
                state.editModel.nro_trans_in = 0;
                state.editModel.nro_trans_out = 0;
                state.editModel.date_process = '';
                state.editModel.date_open = '';
                state.editModel.date_close = '';
            }

            //var today = new Date();
            //Util.abp.clock.today();
        },
    });
}
const cashierhistoryModule=new CashierHistoryModule();
export default cashierhistoryModule;