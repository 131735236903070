import Entity from '../entity'

export default class MeasureUnit extends Entity<number>{
    parent_id: number;
    unit_type_id: number;
    name: string;
    symbol: string;
    code: string;
    precision: number;
    active: boolean;
}


