declare global {
    interface RouterMeta {
        title: string;
    }
    interface Router { 
        path: string;
        name: string;
        icon?: string;
        permission?: string;
        meta?: RouterMeta;
        component: any; 
        children?: Array<Router>;
    } 
    interface System {
        import(request: string): Promise<any>
    }
    var System: System
}
import login from '../views/login.vue'
import home from '../views/home/home.vue'
import main from '../views/main.vue'
import Ajax from '../lib/ajax';

export const locking = {
    path: '/locking',
    name: 'locking',
    component: () => import('../components/lockscreen/components/locking-page.vue')
};
export const apps = {
    path: '/apps',
    name: 'apps',
    component: () => import('../components/apps/components/apps-page.vue')
};
export const loginRouter: Router = {
    path: '/',
    name: 'login',
    meta: {
        title: 'LogIn'
    },
    component: () => import('../views/login.vue')
};
export const otherRouters: Router = {
    path: '/main',
    name: 'main',
    permission: '',
    meta: { title: '' },
    component: main,
    children: [
        { path: 'home', meta: { title: 'Home' }, name: 'home', component: () => import('../views/home/home.vue') },
        { path: '/cashiers/view', meta: { title: 'Caja' }, name: 'cashierdefault', component: () => import('../views/cashiers/cashier/cashier-view.vue') },
        { path: '/cashiers/view/:id', meta: { title: 'Caja' }, name: 'cashierview', component: () => import('../views/cashiers/cashier/cashier-view.vue') },
        { path: '/stocks/stock-mvt-reason', meta: { title: 'Tipos de Movimiento de Stocks' }, name: 'stockmvtreason', component: () => import('../views/stocks/stockmvtreason/stockmvtreason.vue') },
        { path: '/admin/users/profile', meta: { title: 'Perfil de usuario' }, name: 'profile', component: () => import("../views/admin/user/profile.vue") },
        { path: '/preferences', meta: { title: 'Configuracion' }, name: 'preferences', component: () => import("../views/preferences/configuration/configuration.vue") },
        { path: '/reports', meta: { title: 'Reportes' }, name: 'reports', component: () => import("../views/reports/reports.vue") },
        { path: '/reports/sales-sellers', meta: { title: 'Ventas por Vendedor' }, name: 'rsalessellers', component: () => import("../views/reports/sales/sales-sellers.vue") },
        { path: '/reports/sales-query', meta: { title: 'Consulta de Ventas' }, name: 'rsalesquery', component: () => import("../views/reports/sales/sales-query.vue") },
        { path: '/reports/sales-products', meta: { title: 'Ventas por Producto' }, name: 'rsalesproducts', component: () => import("../views/reports/sales/sales-products.vue") },
        { path: '/reports/sales-discounts', meta: { title: 'Ventas por Promocion' }, name: 'rsalesdiscounts', component: () => import("../views/reports/sales/sales-discounts.vue") },
        { path: '/reports/sales-customers', meta: { title: 'Ventas por Cliente' }, name: 'rsalescustomers', component: () => import("../views/reports/sales/sales-customers.vue") },
        { path: '/reports/sales-canceled', meta: { title: 'Ventas Anuladas' }, name: 'rsalescanceled', component: () => import("../views/reports/sales/sales-canceled.vue") },
        { path: '/reports/credit-notes', meta: { title: 'Notas de Credito' }, name: 'rcreditnotes', component: () => import("../views/reports/sales/credit-notes.vue") },
        { path: '/reports/top-clientes', meta: { title: 'Top Clientes' }, name: 'rtopcustomers', component: () => import("../views/reports/sales/top-customers.vue") },
        
        { path: '/reports/sunat-format12', meta: { title: 'Registro de Inventario Permanente' }, name: 'rsunatformat12', component: () => import("../views/reports/sunat/format12.vue") },
        { path: '/reports/sunat-format13', meta: { title: 'Inventario Valorizado' }, name: 'rsunatformat13', component: () => import("../views/reports/sunat/format13.vue") },
        { path: '/reports/sunat-format14', meta: { title: 'Registro de Ventas e Ingresos' }, name: 'rsunatformat14', component: () => import("../views/reports/sunat/format14.vue") },
        { path: '/reports/sunat-format8', meta: { title: 'Registro de Compras' }, name: 'rsunatformat8', component: () => import("../views/reports/sunat/format8.vue") },
	
	    { path: '/reports/pagos-movimientos', meta: { title: 'Pagos y Movimiento de Efectivo' }, name: 'rtransactionmoves', component: () => import("../views/reports/cashiers/transaction-moves.vue") },
        { path: '/reports/flujo-caja', meta: { title: 'Flujo de Caja' }, name: 'rcashierhistory', component: () => import("../views/reports/cashiers/cashier-history.vue") },
        { path: '/reports/ingresos-egresos', meta: { title: 'Reporte Ingresos y Egresos' }, name: 'rinout', component: () => import("../views/reports/cashiers/inout-moves.vue") },
       

        { path: '/reports/stock-almacen', meta: { title: 'Stock por Almacen' }, name: 'rstockwarehouse', component: () => import("../views/reports/stocks/stock-warehouse.vue") },
	    { path: '/reports/stock-disponible', meta: { title: 'Stock Disponible' }, name: 'rstockavailable', component: () => import("../views/reports/stocks/stock-available.vue") },

        { path: '/reports/clientes', meta: { title: 'Consulta de Clientes' }, name: 'rcustomerquery', component: () => import("../views/reports/general/customer-query.vue") },

        { path: '/reports/proveedores', meta: { title: 'Consulta de Proveedores' }, name: 'rsupplierquery', component: () => import("../views/reports/purchases/supplier-query.vue") },
        { path: '/reports/top-productos-comprados', meta: { title: 'Top Productos Comprados' }, name: 'rtopproductspurchase', component: () => import("../views/reports/purchases/top-products.vue") },
        { path: '/reports/top-proveedores', meta: { title: 'Top Proveedores' }, name: 'rtopsuppliers', component: () => import("../views/reports/purchases/top-suppliers.vue") },
        { path: '/reports/facturas-compra', meta: { title: 'Reporte Factuas de Compras' }, name: 'rpurchasequery', component: () => import("../views/reports/purchases/purchases-query.vue") },
	
        { path: '/reports/productos', meta: { title: 'Consulta de Productos' }, name: 'rproductquery', component: () => import("../views/reports/products/product-query.vue") },
    ]
}
export const appRouters: Array<Router> = [];

export const dbRouters = {
    user: () => import("../views/admin/user/user.vue"),
    role: () => import("../views/admin/role/role.vue"),
    //auditLog: () => import("../views/admin/auditLog/auditLog.vue"),
    tab: () => import("../views/admin/tab/tab.vue"),
    application: () => import("../views/admin/application/application.vue"),
    icon: () => import("../views/admin/icon/icon.vue"),
    company: () => import("../views/admin/company/company.vue"),
    branch: () => import("../views/admin/branch/branch.vue"),


    customer: () => import("../views/customers/customer/customer.vue"),
    group: () => import("../views/customers/group/group.vue"),
    documenttype: () => import("../views/customers/documenttype/documenttype.vue"),


    booking: () => import("../views/bookings/booking/booking.vue"),


    category: () => import("../views/catalogs/category/category.vue"),
    manufacturer: () => import("../views/catalogs/manufacturer/manufacturer.vue"),
    measureunit: () => import("../views/catalogs/measureunit/measureunit.vue"),
    product: () => import("../views/catalogs/product/product.vue"),
    pack: () => import("../views/catalogs/pack/pack.vue"),
    service: () => import("../views/catalogs/service/service.vue"), 


    supplier: () => import("../views/purchases/supplier/supplier.vue"),
    purchase: () => import("../views/purchases/purchase/purchase.vue"),
    

    warehouse: () => import("../views/stocks/warehouse/warehouse.vue"),
    stock: () => import("../views/stocks/stock/stock.vue"),
    stockavailable: () => import("../views/stocks/stockavailable/stockavailable.vue"),
    stockmvt: () => import("../views/stocks/stockmvt/stockmvt.vue"), 
    kardex: () => import("../views/stocks/kardex/kardex.vue"),
    stockmvtreason: () => import("../views/stocks/stockmvtreason/stockmvtreason.vue"), 

 
    cashier: () => import("../views/cashiers/cashier/cashier.vue"),    
    cashierview: () => import("../views/cashiers/cashier/cashier-view.vue"),    
    paymentmethod: () => import("../views/cashiers/paymentmethod/paymentmethod.vue"),
    dailyclosure: () => import("../views/cashiers/dailyclosure/dailyclosure.vue"),
    transaction: () => import("../views/cashiers/transaction/transaction.vue"),
    bankaccount: () => import("../views/cashiers/bankaccount/bankaccount.vue"),
    incomeoutput: () => import("../views/cashiers/incomeoutput/incomeoutput.vue"),

    page: () => import("../views/cms/page/page.vue"),
	 
    order: () => import("../views/sales/order/order.vue"),
    sale: () => import("../views/sales/sale/sale.vue"), 
    creditnote: () => import("../views/sales/creditnote/creditnote.vue"),
    sunatsummaries: () => import("../views/sales/sunatsummary/sunatsummary.vue"),
    sunatvoided: () => import("../views/sales/sunatvoided/sunatvoided.vue"), 
    invoicetype: () => import("../views/sales/invoicetype/invoicetype.vue"),
    saleinvoice: () => import("../views/sales/saleinvoice/saleinvoice.vue"),        
} 

export const routers = [
    loginRouter,
    locking,
    apps,
    ...appRouters,
    otherRouters
];
