import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import SunatVoided from '../../entities/sales/sunatvoided'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface SunatVoidedState extends ListState<SunatVoided>{
    editModel:SunatVoided;    
}
class SunatVoidedModule extends ListModule<SunatVoidedState,any,SunatVoided>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<SunatVoided>(),
        loading:false,
        editModel: new SunatVoided(),
        activeList: new Array<SunatVoided>(),
        path: 'sunat-voided'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async invoice(context: ActionContext<SunatVoidedState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/sunat-voided-invoice', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async getLastDocument(context: ActionContext<SunatVoidedState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/sunat-voided/last-document').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.document = result;

            return result;
        },
        async checkDocument(context: ActionContext<SunatVoidedState, any>, payload: any) {
            context.state.loading = true;
            let result = await Ajax.get('/api/sunat-voided/check', { params: payload.data }).then(response => {
                return response.data.result;
            }).catch(e => {
                return false;
            });
            context.state.loading = false;
            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: SunatVoidedState, model: any) {
            var today = new Date();
            state.editModel = Object.assign({});
            state.editModel.date_process = Util.abp.clock.today();
            state.editModel.invoiced = false;
            state.editModel.serie = '';
            state.editModel.number_start = 0;
            state.editModel.number_end = 0;
            state.editModel.document = 'RA-' + state.editModel.date_process.replace('-', '').replace('-', '');

            if (model != null) {
                state.editModel.serie = model.invoice_serie;
                state.editModel.number_start = model.invoice_number;
                state.editModel.number_end = model.invoice_number;
                state.editModel['customer'] = model.customer.fullname;
                state.editModel['invoice'] = model.invoice_formated;
                state.editModel['invoice_type'] = model.invoice_type.name;
            }
        },
    });
}
const sunatvoidedModule=new SunatVoidedModule();
export default sunatvoidedModule;