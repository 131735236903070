<template>
    <div v-shortkey="{quotation: ['alt','q'], order: ['alt','o'], sale: ['alt','v'], payment: ['alt','t'], credit: ['alt','k']}" @shortkey="onKeyEvent">
        <v-speed-dial v-model="fab"
                      bottom right
                      direction="left"
                      open-on-hover
                      transition="scale-transition">
            <template v-slot:activator>
                <v-btn v-model="fab"
                       color="blue darken-2"
                       dark
                       fab>
                    <v-icon v-if="fab">mdi-close</v-icon>
                    <v-icon v-else>visibility</v-icon>
                </v-btn>
            </template>
            <v-btn dark rounded small
                   color="green" @click="quotation" v-if="canCreate('Sales.Quotation')">
                <v-icon left>mdi-plus</v-icon> COTIZACION
            </v-btn>
            <v-btn dark rounded small
                   color="indigo" @click="saleorder" v-if="canCreate('Sales.SaleOrder')">
                <v-icon left>mdi-plus</v-icon> ORDEN
            </v-btn>
            <v-btn dark rounded small
                   color="blue" @click="sale" v-if="canCreate('Sales.Sale')">
                <v-icon left>mdi-plus</v-icon> VENTA
            </v-btn>
            <v-btn dark rounded small
                   color="indigo" @click="payment" v-if="canCreate('Cashiers.Transaction')">
                <v-icon left>mdi-plus</v-icon> PAGO
            </v-btn>
            <v-btn dark rounded small
                   color="cyan" @click="credit" v-if="canCreate('Sales.CreditNote')">
                <v-icon left>mdi-plus</v-icon> NOTA CREDITO
            </v-btn>
        </v-speed-dial>
    </div>
</template>

<script>
    export default {
        name: 'quick-button',
        data: () => ({
            fab: false,            
        }),

        computed: {
            
        },

        watch: {
            
        },

        methods: {
            canCreate(permissionName) {
                return window.abp.auth.hasPermission(permissionName + '.store');
            },

            redirectRoute(route) {
                let routerObj = {};
                routerObj.name = route;
                routerObj.query = { create: true };
                this.$router.push(routerObj);
            },

            quotation() {
                if (this.canCreate('Sales.Quotation')) {
                    this.$store.commit('quotation/new');
                    this.redirectRoute('quotation');
                }
            },
            saleorder() {
                if (this.canCreate('Sales.SaleOrder')) {
                    this.$store.commit('saleorder/new');
                    this.redirectRoute('sale-order');
                }
            },
            sale() {
                if (this.canCreate('Sales.Sale')) {
                    this.$store.commit('sale/new');
                    this.redirectRoute('sale');
                }
            },
            payment() {
                if (this.canCreate('Cashiers.Transaction')) {
                    this.$store.commit('payment/new');
                    this.redirectRoute('transaction');
                }
            },
            credit() {
                if (this.canCreate('Sales.CreditNote')) {
                    this.$store.commit('creditnote/new');
                    this.redirectRoute('credit-note');
                }
            },

            onKeyEvent(event) {
                switch (event.srcKey) {
                    case 'quotation':
                        this.quotation();
                        break
                    case 'saleorder':
                        this.saleorder();
                        break
                    case 'sale':
                        this.sale();
                        break
                    case 'payment':
                        this.payment();
                        break
                    case 'credit':
                        this.credit();
                        break
                }
            }
        }
    }
</script>
<style>
    /* This is for documentation purposes and will not be needed in your application */
    .v-speed-dial {
        position: fixed !important;
    }
    .v-speed-dial--right {
        right: 0px !important;
        width: 56px;
    }
    .v-speed-dial--bottom {
        bottom: 45px !important;
    }
    
</style>