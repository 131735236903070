import Entity from '../entity'

export default class SunatSummary extends Entity<number>{
    document: string;
    sign: string;
    sign_value: string;
    response: string;
    nro_ticket: string;
    filename: string;
    invoiced: boolean;
    serie: string;
    number_start: number;
    number_end: number;
    summary_type: string;
    created_at: string;
    date_process: string;

}


