import Entity from '../entity'

export default class SaleInvoice extends Entity<number>{
    invoice_type_id: number;
    invoice_type_serie_id: number;
    sale_id: number;
    sunat_summary_id: number;
    total_recorded: number;
    total_unaffected: number;
    total_exempt: number;
    total_free: number;
    total: number;
    serie: string;
    number: number;
    note: string;
    nro_ticket: string;
    filename: string;
    response: string;
    qr_code: string;
    invoiced: boolean;
}


