import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PaymentMethod from '../../entities/cashiers/paymentmethod'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface PaymentMethodState extends ListState<PaymentMethod>{
    editModel:PaymentMethod;
    
}
class PaymentMethodModule extends ListModule<PaymentMethodState,any,PaymentMethod>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PaymentMethod>(),
        loading:false,
        editModel: new PaymentMethod(),
        activeList: new Array<PaymentMethod>(),
        path: 'payment-methods'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const paymentmethodModule=new PaymentMethodModule();
export default paymentmethodModule;