import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Printer from '../../entities/preferences/printer'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface PrinterState extends ListState<Printer>{
    editModel:Printer;    
    conntected: Array<any>;    
    templates: Array<any>;    
}
class PrinterModule extends ListModule<PrinterState,any,Printer>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Printer>(),
        loading:false,
        editModel: new Printer(),
        activeList: new Array<Printer>(),
        conntected: new Array<any>(),
        templates: new Array<any>(),
        path: 'printers'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<PrinterState, any>, payload: any) {
            context.state.loading = true;
            if (payload.data.characters == undefined && payload.data['characters_id'] != undefined) {
                payload.data.characters = payload.data['characters_id'];
                delete payload.data['characters_id'];
            }
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (response.data.result)
                context.state.activeList.push(response.data.result);
            return response.data.result;
        },
        async update(context: ActionContext<PrinterState, any>, payload: any) {
            context.state.loading = true;
            if (payload.data.characters == undefined && payload.data['characters_id'] != undefined) {
                payload.data.characters = payload.data['characters_id'];
                delete payload.data['characters_id'];
            }
            await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async getConnectedPrinters(context: ActionContext<PrinterState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/printers-connected').catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.conntected = reponse.data.result;
        },
        async getTemplates(context: ActionContext<PrinterState, any>, payload: any) {
            let reponse = await Ajax.get('/api/printers-templates').catch(e => {
                return Promise.reject(e);
            });
            context.state.templates = reponse.data.result;
        },
        async printTest(context: ActionContext<PrinterState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.put('/api/print-test/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: PrinterState, model: Printer) {
            state.editModel = model;
            state.editModel['name1'] = model.name;
        },
    });
}
const printerModule=new PrinterModule();
export default printerModule;