import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Group from '../../entities/customers/group'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface GroupState extends ListState<Group>{
    editModel:Group;
    
}
class GroupModule extends ListModule<GroupState,any,Group>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Group>(),
        loading:false,
        editModel: new Group(),
        activeList: new Array<Group>(),
        path: 'groups'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const groupModule=new GroupModule();
export default groupModule;