import { Component, Vue,Inject } from 'vue-property-decorator';
import appconst from './appconst'
import Util from './util'

export default class AbpBase extends Vue {
    permissionName: string = null;
    L(value:string,source?:string,...argus:string[]):string{
        if(source){ 
            return window.abp.localization.localize(value,source,argus);
        }else{
            return window.abp.localization.localize(value,appconst.localization.defaultLocalizationSourceName,argus);
        }
        
    }
    getRoutePermission() {
        if (this.permissionName != null && this.permissionName != '') return this.permissionName;
        let routerObj = Util.getRouterObjByName(Util.abp.tabList, this.$store.state.app.currentPageName);
        return routerObj != null ? routerObj.permission : '';
    }
    canView(permissionName: string = '') {
        if (permissionName == '') permissionName = this.getRoutePermission();
        return window.abp.auth.hasPermission(permissionName + '.view');
    }
    canCreate(permissionName: string = '') {
        if (permissionName == '') permissionName = this.getRoutePermission();
        return window.abp.auth.hasPermission(permissionName + '.store');
    }
    canEdit(permissionName: string = '') {
        if (permissionName == '') permissionName = this.getRoutePermission();
        return window.abp.auth.hasPermission(permissionName + '.update');
    }
    canDelete(permissionName: string = '') {
        if (permissionName == '') permissionName = this.getRoutePermission();
        return window.abp.auth.hasPermission(permissionName + '.destroy');
    }
    canExport(permissionName: string = '') {
        if (permissionName == '') permissionName = this.getRoutePermission();
        return window.abp.auth.hasPermission(permissionName + '.export');
    }
    hasPermission(permissionName:string){
        return window.abp.auth.hasPermission(permissionName);
    }
    hasAnyOfPermissions(...argus:string[]){
        return window.abp.auth.hasAnyOfPermissions(...argus);
    }
    hasAllOfPermissions(...argus:string[]){
        return window.abp.auth.hasAllOfPermissions(...argus);
    }
}