import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import DailyClosure from '../../entities/cashiers/dailyclosure'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface DailyClosureState extends ListState<DailyClosure>{
    editModel: DailyClosure;
    isProcessed: boolean;
    reportList: Array<any>; 
}
class DailyClosureModule extends ListModule<DailyClosureState,any,DailyClosure>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<DailyClosure>(),
        loading:false,
        editModel: null,
        isProcessed: false,
        reportList: new Array<any>(),
        path: 'daily-closures'         
    }
    actions = {
        async getAll(context: ActionContext<DailyClosureState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.totalCount = reponse.data.result.length;
            context.state.reportList = reponse.data.result;
        },
        async checkProcessedDay(context: ActionContext<DailyClosureState, any>, payload: any) {
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/processed').catch(e => {
                return Promise.reject(e);
            });
            context.state.isProcessed = reponse.data.result;
        },
        async processDay(context: ActionContext<DailyClosureState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.isProcessed = true;
            return true;
        },
    };
    mutations = {
        //extra mutations
    };
}
const dailyclosureModule=new DailyClosureModule();
export default dailyclosureModule;