import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import StockMvtReason from '../../entities/stocks/stockmvtreason'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface StockMvtReasonState extends ListState<StockMvtReason>{
    editModel: StockMvtReason;
    activeList: Array<StockMvtReason>;
}
class StockMvtReasonModule extends ListModule<StockMvtReasonState, any, StockMvtReason>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<StockMvtReason>(),
        loading:false,
        editModel:new StockMvtReason(),
        activeList: new Array<StockMvtReason>(),
        path: 'stock-mvt-reasons'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const stockmvtreasonModule=new StockMvtReasonModule();
export default stockmvtreasonModule;