import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Report from '../../entities/admin/report'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ReportState extends ListState<Report>{
    editModel:Report;
    tree: Array<Report>;
    loadingReport:boolean;  
}
class ReportModule extends ListModule<ReportState,any,Report>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Report>(),
        loading:false,
	    loadingReport:false,
        editModel: new Report(),
        activeList: new Array<Report>(),
        tree: new Array<Report>(),
        path: 'reports'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getReports(context: ActionContext<ReportState, any>, payload: any) {
            if (context.state.tree != undefined && context.state.tree.length > 0) return context.state.tree;
            context.state.loading = true;
            let result = await Ajax.get("/api/reports-tree");
            context.state.loading = false;
            context.state.tree = result.data.result;
            return context.state.tree;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const reportModule=new ReportModule();
export default reportModule;