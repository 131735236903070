import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Application from '../../entities/admin/application'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ApplicationState extends ListState<Application>{
    editModel:Application;
    loadingApplication:boolean;  
}
class ApplicationModule extends ListModule<ApplicationState,any,Application>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Application>(),
        loading:false,
	loadingApplication:false,
        editModel: new Application(),
        activeList: new Array<Application>(),
        path: 'applications'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions        
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const applicationModule=new ApplicationModule();
export default applicationModule;