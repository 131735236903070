import Entity from '../entity'

export default class InvoiceTypeSerie extends Entity<number>{
    invoice_type_id: number;
    branch_id: number;
    printer_id: number;
    serie: string;
    number: number;
    active: boolean;
}


