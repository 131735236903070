import ajax from '../../lib/ajax';
import util from '../../lib/util'
import {Store,Module,ActionContext} from 'vuex' 
import Address from '../entities/customers/address';

interface SessionState{
    application: any,
    user: any,
    company: any,
    companyId: any,
    cashier: any
}
class SessionStore implements Module<SessionState,any>{
    namespaced=true;
    state={
        application: null,
        user: null,
        company: null,
        companyId: null,
        cashier: null
    }
    actions={
        async init(content:ActionContext<SessionState,any>){
            let rep = await ajax.get('/api/auth/getCurrentLoginInformations',{headers:{}});
            content.state.application = rep.data.result.application;
            content.state.user = rep.data.result.user;
            content.state.company = rep.data.result.company;
            content.state.cashier = rep.data.result.cashier;
            content.state.companyId = rep.data.result.company.id;
            if (content.state.company.address == null)
                content.state.company.address = new Address();
        }
    }
    mutations = {
        setCompany(state: SessionState, company: any) {
            if (company != null) {
                state.company = company;
                state.companyId = company.id;
                if (state.company.address == null)
                    state.company.address = new Address();
            }
        },
        setCashier(state: SessionState, cashier: any) {
            if (cashier != null) {
                state.cashier = cashier;
            }
        }
    }
}
const session=new SessionStore();
export default session;